import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Head from "../components/head";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { Link } from "gatsby";

export const query = graphql`
	query {
		allContentfulOkrTemplate {
			edges {
				node {
					id
					title
					slug
					publishedDate(formatString: "MMMM Do, YYYY")
					excerpt
					createdAt(formatString: "DD-MM-YYYY")
					updatedAt
				}
			}
		}
		site {
			siteMetadata {
				title
				description
				siteUrl
			}
		}
		contentfulRichText(slug: { eq: "okr-templates" }) {
			id
			body {
				raw
			}
		}
	}
`;

const OkrTemplates = (props) => {
	const site = props.data.site;
	const templates = props.data.allContentfulOkrTemplate.edges;
	const body = props.data.contentfulRichText.body;
	const description =
		"Trouble finding the right OKR templates? We've gathered and created a few different templates for popular tools that you can get right here.";

	return (
		<Layout>
			<Head
				title={`OKR Templates -> Browse templates for tracking OKRs | ${site.siteMetadata.title}`}
				description={description}
				url={`${props.data.site.siteMetadata.siteUrl}/okr-templates/`}
			/>

			<main className="max-w-4xl mx-auto mt-4">
				<div className="mt-8 sm:mt-12 md:mt-16">
					<h1 className="text-4xl font-bold tracking-tight text-slate-900 sm:text-5xl md:text-6xl">
						<span className="block xl:inline">OKR Templates</span>
					</h1>
					<p className="mt-3 text-base text-slate-500 sm:mt-5 sm:text-lg md:mt-5 md:text-xl">
						{description}
					</p>
				</div>

				<div className="mt-4 md:mt-8">
					<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
						{templates.map((edge) => {
							return (
								<div
									key={edge.node.id}
									className="rounded-md bg-slate-100 border p-4">
									<div className="flex flex-col">
										<h2 className="text-3xl font-semibold mb-4 mt-1 break-words">
											{edge.node.title}
										</h2>
										<p className="">{edge.node.excerpt}</p>
										<div className="align-bottom flex justify-between items-center self-end w-full mt-2">
											<Link
												className="underline font-semibold text-indigo-500 hover:text-indigo-400 active:text-indigo-600"
												to={`/okr-templates/${edge.node.slug}`}>
												Learn more →
											</Link>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>

				<div
					className="mt-4 md:mt-8 md:my-16 lg:my-20
        prose-p:text-lg prose-p:my-6 prose-blockquote:my-4 prose-blockquote:bg-indigo-500 prose-blockquote:text-white prose-blockquote:mb-4 prose-blockquote:rounded prose-blockquote:px-4 prose-blockquote:py-1
        prose-h2:mt-8 prose-h2:mb-4 prose-h2:text-4xl prose-h2:font-semibold prose-h2:text-slate-800
        prose-h3:mb-4 prose-h3:mt-6 prose-h3:text-3xl prose-h3:font-bold prose-h3:text-slate-900
        prose-li:list-disc prose-li:ml-3 prose-li:mt-1.5 prose-li:text-thin prose-li:text-slate-800
        prose-a:text-indigo-600 prose-a:underline">
					{renderRichText(body)}
				</div>
			</main>
		</Layout>
	);
};

export default OkrTemplates;
